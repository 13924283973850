import classNames from "classnames";

const BaseTabs = ({ children, tabs = [], current, onTab, counts, sticky = false }) => {
	return (
		<div className="base-tabs">
			<div className={classNames("header", { sticky })}>
				{tabs.map(tab => (
					<div
						className={classNames("tab", { selected: tab === current })}
						onClick={() => onTab(tab)}
						key={tab}
					>
						{tab} {counts && (
							<span>{counts[tab]}</span>
						)}
					</div>
				))}
			</div>

			<div className="tab-content">
				{children}
			</div>
		</div>
	)
};

export default BaseTabs;