import { useCallback } from "react";

const BaseCheckbox = ({ onChange, onValue, checked, text }) => {
	const processChange = useCallback((e) => {
		if (onChange) onChange(e);
		if (onValue) onValue(e.target.checked);
	}, [onChange, onValue]);

	return (
		<label className="base-checkbox">
			<input type="checkbox" checked={checked} onChange={processChange} />
			<div className="check" />
			<span>{text}</span>
		</label>
	)
};

export default BaseCheckbox;