import { useCallback, useEffect, useMemo, useState } from "react";
import { Categories } from "utils/constants";
import Dropdown from "components/Base/Dropdown";

import IconDown from "./img/arrow-down.png";

const TagsSelect = ({ onChange, init = [] }) => {
	const [tags, setTags] = useState(init);

	useEffect(() => {
		if (init.length) {
			setTags(init);
		}
	}, [init]);

	const addTag = useCallback((name) => {
		setTags(prev => [name, ...prev]);
	}, []);

	const removeTag = useCallback((name, event) => {
		event.stopPropagation();
		setTags(prev => prev.filter(t => t !== name));
	}, []);

	useEffect(() => {
		onChange(tags);
	}, [onChange, tags]);

	const restCategories = useMemo(() => {
		return Categories.filter(cat => !tags.includes(cat));
	}, [tags]);

	return (
		<div className="tags-select">
			<Dropdown items={restCategories} autoclose={false} onChange={val => addTag(val)}>
				{tags.map((tag) => (
					<div className="tag btn" key={tag} onClick={(e) => removeTag(tag, e)}>{tag}</div>
				))}
				<div className="add btn">Add job tags <img className="arrow" src={IconDown} alt="Dropdown" /></div>
			</Dropdown>
		</div>
	)
};

export default TagsSelect;