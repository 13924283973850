import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";

import BaseTabs from "components/Base/BaseTabs";

import IconMessage from "./img/message.png";
import MessageModal from "components/MessageModal/MessageModal";

const Tabs = ["pending", "hired", "rejected", "completed"];

const MyBids = () => {
	const [currentTab, setCurrentTab] = useState(Tabs[0]);
	const [jobs, setJobs] = useState([]);

	const [showMessageModal, setShowMessageModal] = useState(false);
	const [messageModalCustomer, setMessageModalCustomer] = useState(null);

	useEffect(() => {
		axios.get("/profile/bids").then(({ data }) => {
			setJobs(data);
		});
	}, []);

	const tabJobs = useMemo(() => {
		if (currentTab === "pending") {
			return jobs.filter(job => job.status === "active" && job.bid.status === "pending");
		}

		if (currentTab === "hired") {
			return jobs.filter(job => job.status === "working" && job.bid.status === "hired");
		}

		if (currentTab === "rejected") {
			return jobs.filter(job => job.bid.status === "rejected");
		}

		if (currentTab === "completed") {
			return jobs.filter(job => job.status === "completed" && job.bid.status === "hired");
		}

		return [];
	}, [jobs, currentTab]);

	return (
		<div className="page bids mybids">
			<h1>My Bids</h1>

			<BaseTabs tabs={Tabs} onTab={setCurrentTab} current={currentTab} sticky>
				{!tabJobs.length && (
					<div className="empty"><span>{currentTab}</span> list is empty</div>
				)}

				{tabJobs.map((job) => (
					<div className="bid" key={job._id}>
						<div className="content">
							<Link className="title" to={`/jobs/${job._id}`}>{job.title}</Link>
							<div className="info">
								<div className="item">Customer: <b>{job.creator.name} {job.creator.surname}</b></div>
								<div className="item">Date:<b>{moment(job.bid.date_create).format("DD MMM. YYYY - HH:mm")}</b></div>
								<div className="item">
									Requested Price:
									<b>{job.bid.price ? `Suggested $${job.bid.price}` : `Fixed $${job.price}`}</b>
								</div>
							</div>

							<div className="more">
								<div className="message">{job.bid.message}</div>

								{job.bid.status === "hired" && (
									<div className="buttons">
										<button className="btn bordered message" onClick={() => { setShowMessageModal(true); setMessageModalCustomer(job.creator) }}>
											<img src={IconMessage} alt="Message" />
											<span>Message</span>
										</button>
									</div>
								)}
							</div>
						</div>
					</div>
				))}
			</BaseTabs>

			<MessageModal show={showMessageModal} onClose={() => setShowMessageModal(false)} user={messageModalCustomer} />
		</div>
	)
};

export default MyBids;