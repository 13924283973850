import classNames from "classnames";

import IconClose from "./img/close.svg";

const BaseModal = ({ show, onClose, children, width = 450, className = "" }) => {
	return show && (
		<div className="overlay instant show" onClick={onClose}>
			<div className={classNames("base-modal", className)} style={{ width }} onClick={e => e.stopPropagation()}>
				<img src={IconClose} className="close" alt="Close" title="Close" onClick={onClose} />
				{children}
			</div>
		</div>
	)
};

export default BaseModal;