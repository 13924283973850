import { Link } from "react-router-dom";

import IconFacebook from "./img/facebook.svg";
import IconTwitter from "./img/twitter.svg";
import IconInstagram from "./img/instagram.svg";
import IconYoutube from "./img/youtube.svg";

const Footer = () => {
	return (
		<div className="app-footer">
			<div className="wrap">
				<div className="links">
					<div className="pages">
						<Link to="/about">About us</Link>
						<Link to="/about/caregivers">For Caregivers</Link>
						<Link to="/about/how">How It Works</Link>
					</div>
					<div className="social">
						<a href="//facebook.com"><img src={IconFacebook} alt="Facebook" /></a>
						<a href="//twitter.com"><img src={IconTwitter} alt="Twitter" /></a>
						<a href="//instagram.com"><img src={IconInstagram} alt="Instagram" /></a>
						<a href="//youtube.com"><img src={IconYoutube} alt="Youtube" /></a>
					</div>
				</div>

				<div className="bottom">
					<div className="copyright">
						© 2023 Emissary. All rights reserved
					</div>

					<div className="policy">
						<Link to="/terms">Terms Of Use</Link>
						<Link to="/policy">Privacy Policy</Link>
					</div>
				</div>
			</div>
		</div>
	)
};

export default Footer;