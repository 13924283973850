export const JobEndTimes = {
	8: 12,
	12: 16,
	16: 22
};

export const Categories = [
	"Managing medications",
	"Prescription pick up/drop off",
	"Telehealth support",
	"Groceries",
	"Meal preparation",
	"Laundry",
	"Companionship",
	"Mobility help",
	"Insurance navigation",
	"Medical supply organization"
];