import "./global.scss";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";

import { useStore } from "./store";
import { authSocket } from "services/socket";

// Common components
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import Signin from "components/Signin/Signin";
import Notifications from "components/Notifications/Notifications";

// Pages
import Home from "pages/Home/Home";
import Signup from "pages/Signup/Signup";
import Profile from "pages/Profile/Profile";
import JobCreate from "pages/JobCreate/JobCreate";
import JobsDetails from "pages/JobDetails/JobDetails";
import MyOrders from "pages/MyOrders/MyOrders";
import OrderBids from "pages/MyOrders/OrderBids";
import ProfileEdit from "pages/ProfileEdit/ProfileEdit";
import Chats from "pages/Chats/Chats";
import MyBids from "pages/MyOrders/MyBids";
import Policy from "pages/Policy/Policy";
import Terms from "pages/Policy/Terms";
import AboutUs from "pages/About/AboutUs";
import ForProviders from "pages/About/ForProviders";
import HowItWorks from "pages/About/HowItWorks";

const GoogleClientId = process.env.REACT_APP_GOOGLE_CLIENT;
const PaypalClientId = process.env.REACT_APP_PAYPAL_CLIENT;
const PaypalAttributionId = process.env.REACT_APP_PAYPAL_ATTRIBUTION;

const Router = observer(() => {
	const store = useStore();
	const { isAuth, user } = store.auth;

	useEffect(() => {
		if (isAuth) {
			store.auth.fetchUserInfo();
			authSocket();
		}
	}, [isAuth, store.auth]);

	return isAuth ? (
		<Routes>
			<Route path="/" element={<Navigate to={user.isCustomer ? "/providers" : "/jobs"} />} />

			<Route path="/providers" element={<Home type="providers" />} />
			<Route path="/jobs" element={<Home type="jobs" />} />

			<Route path="/jobs/create" element={<JobCreate />} />
			<Route path="/jobs/my" element={<MyOrders />} />
			<Route path="/jobs/:id" element={<JobsDetails />} />
			<Route path="/jobs/:id/edit" element={<JobCreate />} />
			<Route path="/jobs/:id/bids" element={<OrderBids />} />
			<Route path="/bids" element={<MyBids />} />

			<Route path="/profile" element={<ProfileEdit />} />
			<Route path="/customers/:id" element={<Profile />} />
			<Route path="/providers/:id" element={<Profile />} />

			<Route path="/chats" element={<Chats />} />

			<Route path="/policy" element={<Policy />} />
			<Route path="/terms" element={<Terms />} />
			<Route path="/about" element={<AboutUs />} />
			<Route path="/about/caregivers" element={<ForProviders />} />
			<Route path="/about/how" element={<HowItWorks />} />

			<Route path="*" element={<Navigate to="/" />} />
		</Routes>
	) : (
		<>
			<Routes>
				<Route path="/auth" element={<Signup />} />
				<Route path="/auth/:type" element={<Signup />} />

				<Route path="/policy" element={<Policy />} />
				<Route path="/terms" element={<Terms />} />
				<Route path="/about" element={<AboutUs />} />
				<Route path="/about/caregivers" element={<ForProviders />} />
				<Route path="/about/how" element={<HowItWorks />} />

				<Route path="*" element={<Navigate to="/auth" />} />
			</Routes>
			<Signin />
		</>
	);
});


const AutoTopScroll = () => {
	const location = useLocation();

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, [location]);
};


const AppRoot = () => {
	return (
		<PayPalScriptProvider options={{ "client-id": PaypalClientId, "data-partner-attribution-id": PaypalAttributionId }}>
			<GoogleOAuthProvider clientId={GoogleClientId}>
				<BrowserRouter>
					<div className="app">
						<Header />
						<Router />
						<Footer />
						<Notifications />
						<AutoTopScroll />
					</div>
				</BrowserRouter>
			</GoogleOAuthProvider>
		</PayPalScriptProvider>
	);
}

export default AppRoot;
