import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { getAvatar } from "utils/helpers";
import { useStore } from "../../store";

import Dropdown from "components/Base/Dropdown";

import IconLogo from "./img/logo.svg";
import IconSearch from "./img/search.svg";
import IconArrow from "./img/arrow-search.svg";
import IconNewJob from "./img/job.png";
import IconChats from "./img/chats.png";
import IconMenuArrow from "./img/menu-arrow.svg";
// import IconUser from "./img/user.svg";
// import IconOrders from "./img/orders.png";


const Header = () => {
	const [searchType, setSearchType] = useState("Caregiver");
	const [showMenu, setShowMenu] = useState(false);

	const store = useStore();
	const navigate = useNavigate();

	const { isAuth, user } = store.auth;

	useEffect(() => {
		setSearchType(user.isCustomer ? "Caregiver" : "Job");
	}, [user]);

	const openMenu = useCallback(event => {
		event.stopPropagation();
		setShowMenu(p => !p);
	}, []);

	useEffect(() => {
		const closeHandler = () => {
			setShowMenu(false);
		};

		document.addEventListener("click", closeHandler);
		return () => document.removeEventListener("click", closeHandler);
	}, []);

	const showSignIn = useCallback(() => {
		store.auth.toggleModal(true);
	}, [store.auth]);

	const signOut = useCallback(() => {
		store.auth.unauthorize();
	}, [store.auth]);

	const handlerSearch = useCallback((e) => {
		const value = e.target.search.value;
		const page = searchType === "Job" ? "/jobs" : "/providers";

		navigate(page, {
			state: {
				search: value
			}
		});

		e.target.search.value = "";
		e.preventDefault();
	}, [searchType, navigate]);

	return (
		<div className="app-header">
			<div className="wrap">
				<Link to="/" className="logo">
					<img src={IconLogo} alt="Logo" />
				</Link>

				<div className="search">
					<form className="field" onSubmit={handlerSearch}>
						<img src={IconSearch} alt="Search" />
						<input name="search" type="text" placeholder="I'm looking for..." />
					</form>

					<Dropdown items={["Caregiver", "Job"]} onChange={setSearchType}>
						<div className="type btn">
							<span>{searchType}</span>
							<img src={IconArrow} className="arrow" alt="Arrow" />
						</div>
					</Dropdown>
				</div>

				{isAuth ? (
					<>
						{/* <div className="btn">Create order</div> */}

						<div className="auth full">
							<div className="menu-links">
								{user.isCustomer && (
									<Link to="/jobs/create" className="btn icon" title="Post a Job">
										<img src={IconNewJob} alt="Post a Job" />
										<span>Post a Job</span>
									</Link>
								)}

								<Link to="/chats" className={classNames("btn icon", { marked: store.notif.unreadedChats })} title="Chats">
									<img src={IconChats} alt="Chats" />
									<span>Chats</span>
								</Link>
							</div>

							{/* <div className="balance">
								<div className="title">Balance</div>
								<div className="value">${store.auth.user.balance}</div>
							</div> */}

							<Link to="/chats" className={classNames("btn mobile-chat", { marked: store.notif.unreadedChats })} title="Chats">
								<img src={IconChats} alt="Chats" />
							</Link>

							<div className="user" onClick={openMenu}>
								<img src={getAvatar(user.avatar)} alt="User" className="avatar" />
								<div className="info">
									<div className="title">{user.type}</div>
									<div className="value">{user.name}</div>
								</div>
								<img src={IconMenuArrow} alt="Menu" className={classNames("arrow", { opened: showMenu })} />

								<div className={classNames("menu", { show: showMenu })}>
									{/* <div className="item mobile-balance">
										<span>Balance:</span>
										<b>${store.auth.user.balance}</b>
									</div> */}
									{user.isCustomer && (
										<>
											<Link to="/jobs/create" className="item create">Post a job</Link>
											<Link to="/jobs/my" className="item">My Orders</Link>
										</>
									)}

									{user.isProvider && (
										<>
											<Link to="/bids" className="item">My Bids</Link>
										</>
									)}
									<div className="separator"></div>
									<Link to={`/${user.type}s/${user.id}`} className="item">My profile</Link>
									<Link to="/profile" className="item">Settings</Link>
									<div className="separator"></div>
									<div className="item signout" onClick={signOut}>Sign Out</div>
								</div>
							</div>
						</div>
					</>
				) : (
					<>
						<div className="links">
							<Link to="/about" className="link">About Us</Link>
							<Link to="/about/caregivers" className="link">For Caregivers</Link>
							<Link to="/about/how" className="link">How it works</Link>
						</div>

						<div className="auth">
							<div className="login" onClick={showSignIn}>Login</div>
							<div className="signup btn" onClick={() => navigate("/auth")}>Sign Up</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default observer(Header);