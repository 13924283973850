import axios from "axios";
import classNames from "classnames";
import { useMemo, useState } from "react";

import Confirmation from "./Confirmation";
import CategoriesPicker from "./CategoriesPicker";
import Loader from "components/Base/Loader";

import IconStepCheck from "./img/step-check.svg";
import IconCat from "./img/cat-icon.svg";
import { errorNotification } from "utils/helpers";

const Stepper = ({ step, nextStep, userType, signInfo, finish, categories, selectCategory, isGoogleAuth }) => {
	const [waitConfirmEmail, setWaitConfirmEmail] = useState(false);
	const [confirmEmail, setConfirmEmail] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const sendEmailConfirmation = () => {
		if (!waitConfirmEmail) {
			setIsLoading(true);
			axios.post("/auth/signup/verify/send", { email: signInfo.email }).then(() => {
				setWaitConfirmEmail(true);
			}).catch(error => {
				console.log(error);
				errorNotification(error, true);
			}).finally(() => {
				setIsLoading(false);
			});
		}
		else {
			const confirmFilled = confirmEmail.every(i => i.length);
			if (confirmFilled) {
				setIsLoading(true);
				axios.post("/auth/signup/verify/enter", {
					email: signInfo.email,
					code: confirmEmail.join("")
				}).then(() => {
					nextStep();
				}).catch(error => {
					errorNotification(error, true);
					setConfirmEmail([]);
				}).finally(() => {
					setIsLoading(false);
				});
			}
		}
	};

	const confirmEmailFilled = useMemo(() => confirmEmail.every(i => i.length), [confirmEmail]);
	const maxSteps = useMemo(() => (userType === "customer" ? 2 : 3), [userType]);

	if (step >= maxSteps) {
		finish();
		return null;
	}

	return (
		<div className="stepper">
			<div className="header">
				<div className="title">Confirm your information</div>
				<div className="desc">To completed registration please comfirm your information</div>
			</div>

			<div className="wrap">
				<div className="steps">
					{/* <div className={classNames("step", { completed: step > 1, current: step >= 1 })}>
						<img src={IconStepCheck} alt="Step icon" />
						Confirm Phone
					</div> */}

					<div className={classNames("step", { completed: step > 1, current: step >= 1 })}>
						<img src={IconStepCheck} alt="Step icon" />
						Confirm Email
					</div>

					{userType === "provider" && (
						<div className={classNames("step", { completed: step > 2, current: step >= 2 })}>
							<img src={IconStepCheck} alt="Step icon" />
							Select Category
						</div>
					)}

					<div className="step">
						<img src={IconStepCheck} alt="Step icon" />
						Fill Your Profile
					</div>
				</div>

				{/* {step === 1 && (
					<div className="content">
						<div className="contact">{signInfo.phone}</div>

						{waitConfirmPhone ? (
							<Confirmation setConfirm={setConfirmPhone} />
						) : (
							<div className="desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</div>
						)}

						<button className="confirm" onClick={sendPhoneConfirmation} disabled={waitConfirmPhone && !confirmPhoneFilled}>
							{waitConfirmPhone ? "Apply" : "Send Verify Code"}
						</button>
					</div>
				)} */}

				{step === 1 && (
					<div className="content">
						<div className="contact">{signInfo.email}</div>

						{waitConfirmEmail ? (
							<Confirmation setConfirm={setConfirmEmail} />
						) : (
							<div className="desc">A confirmation code will be sent to your email. If you don't see it in your inbox, check the Promotions and the Spam folders.</div>
						)}

						<button className="confirm" onClick={sendEmailConfirmation} disabled={waitConfirmEmail && !confirmEmailFilled}>
							{waitConfirmEmail ? "Apply" : "Send confirmation code"}
						</button>
					</div>
				)}

				{step === 2 && userType === "provider" && (
					<>
						<div className="content categories">
							<div className="desc center">Please select the categories of services you want to provide</div>

							<CategoriesPicker selected={categories} selectCategory={selectCategory} />
						</div>

						<div className="categories-list">
							<div className="sticky">
								<div className="block">
									<div className="title">You can choose <b>{categories.length}</b> categories:</div>
									{categories.map((cat, index) => (
										<div className="cat" key={index} onClick={() => selectCategory(cat)}>
											<img src={IconCat} alt="Icon" />
											<span>{cat}</span>
										</div>
									))}
								</div>

								<button className="confirm" onClick={nextStep} disabled={!categories.length}>
									Next
								</button>
							</div>
						</div>
					</>
				)}

				<Loader show={isLoading} />
			</div>
		</div>
	);
};

export default Stepper;