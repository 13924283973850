import { Link } from "react-router-dom";

import TagsSplit from "./TagsSplit";

import IconLocation from "./img/location.svg";

const CardProvider = ({ data }) => {
	return (
		<Link to={`/providers/${data._id}`} className="card provider">
			<div className="title">{data.name} {data.surname}</div>

			<div className="category">
				<img src={IconLocation} alt="Location" />
				<span>{Object.values(data.location).filter(i => !!i).join(", ")}</span>
			</div>

			<div className="description">{data.about}</div>

			<TagsSplit tags={data.tags} max={38} />

			{/* <div className="rate">
				<span>Hourly rate:</span>
				<div className="price">{data.rate}$/h</div>
			</div> */}
		</Link>
	)
};

export default CardProvider;