import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { inNodePath } from "utils/helpers";

// import IconItem from "./img/dropdown-item.svg";

const Dropdown = ({ children, items = [], onChange, autoclose = true, withReset }) => {
	const [opened, setOpened] = useState(false);
	const dropdown = useRef();

	useEffect(() => {
		const closeDropdown = (e) => {
			const isDropdown = e.path.includes(dropdown.current);

			if (isDropdown) {
				const isMenu = inNodePath(e.path, "menu");
				if (isMenu && !autoclose) {
					return e.stopPropagation();
				}

				setOpened(o => !o);
			}
			else {
				setOpened(false);
			}
		};

		document.body.addEventListener("click", closeDropdown);
		return () => {
			document.body.removeEventListener("click", closeDropdown);
		}
	}, [autoclose]);

	const selectOne = useCallback((item) => {
		if (onChange) onChange(item);
	}, [onChange]);

	return (
		<div className={classNames("base-dropdown", { opened })} ref={dropdown}>
			{children}

			<div className={classNames("menu", { show: opened })}>
				{withReset && (
					<div className="item unselect" onClick={() => selectOne("")}>- Remove selected -</div>
				)}
				{items.map((item, index) => (
					<div className="item" key={index} onClick={() => selectOne(item)}>
						{item}
					</div>
				))}

				{!items.length && (
					<div className="empty">List is empty</div>
				)}
			</div>
		</div>
	)
};

export default Dropdown;