import { PayPalButtons } from "@paypal/react-paypal-js";

const PaypalButton = ({ onOrder, onError, amount, type, payee }) => { // Todo: Handle errors
	const fee = (amount * 0.2).toFixed(2);

	return (
		<PayPalButtons
			fundingSource={type}
			style={{
				label: "pay",
				tagline: false,
				layout: "vertical"
			}}
			createOrder={(order, actions) => {
				return actions.order.create({
					intent: "CAPTURE",
					purchase_units: [{
						amount: {
							currency_code: "USD",
							value: amount
						},
						payee: {
							merchant_id: payee
						},
						payment_instruction: {
							disbursement_mode: "DELAYED",
							platform_fees: [{
								amount: {
									currency_code: "USD",
									value: fee
								}
							}]
						}
					}]
				})
			}}
			onApprove={(approve, actions) => {
				console.log("Paypal Approve", approve); // Todo: remove
				return actions.order.capture().then((capture) => {
					console.log("Paypal Capture", capture);
					onOrder(capture.purchase_units[0]?.payments?.captures[0]?.id);
				});
			}}
		/>
	)
};

export default PaypalButton;