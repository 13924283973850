import axios from "axios";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useState } from "react";

import BaseTabs from "components/Base/BaseTabs";
import Completed from "components/Base/Completed";
import BaseModal from "components/Base/BaseModal";
import Loader from "components/Base/Loader";
import ReviewModal from "./ReviewModal";

import IconEdit from "./img/edit.png";
import IconBids from "./img/bids.png";
import IconReview from "./img/review.png";
import IconArchive from "./img/archive.png";
import IconDelete from "./img/delete.png";
import IconCompleted from "./img/done.svg";

const Tabs = ["active", "working", "completed", "archive"];


const MyOrders = () => {
	const [jobs, setJobs] = useState([]);
	const [currentTab, setCurrentTab] = useState(Tabs[0]);
	const [completed, setCompleted] = useState(false);
	const [fetching, setFetching] = useState(true);

	const [modalJob, setModalJob] = useState(null);
	const [showModal, setShowModal] = useState(false);

	const tabJobs = useMemo(() => {
		return jobs.filter(job => job.status === currentTab.replace(" ", "")) || [];
	}, [jobs, currentTab]);

	const tabsCount = useMemo(() => {
		const count = {};
		Tabs.forEach(tab => {
			count[tab] = jobs.filter(job => job.status === tab.replace(" ", "")).length || 0;
		});
		return count;
	}, [jobs]);

	const fetchJobs = useCallback(() => {
		setFetching(true);

		axios.get("/jobs/my").then(({ data }) => {
			for (const job of data) {
				job.new_bids = job.bids.reduce((acc, bid) => {
					return bid.status === "pending" ? acc + 1 : acc;
				}, 0);

				job.executor = job.bids.find(bid => bid.status === "hired")?.provider;
			};
			setJobs(data);
		}).catch(e => {
			console.log(e);
		}).finally(() => {
			setFetching(false);
		});
	}, []);

	useEffect(() => {
		fetchJobs();
	}, [fetchJobs]);

	const sendCompleteJob = useCallback(() => {
		axios.patch(`/jobs/${modalJob._id}/complete`).then(() => {
			setCompleted(true);
			setShowModal(false);
			fetchJobs();
		});
	}, [fetchJobs, modalJob]);

	const sendArchiveJob = useCallback(() => {
		axios.patch(`/jobs/${modalJob._id}/archive`).then(({ data }) => {
			setCompleted(true);
			setShowModal(false);
			fetchJobs();
		});
	}, [fetchJobs, modalJob]);

	const sendDeleteJob = useCallback(() => {
		axios.delete(`/jobs/${modalJob._id}`).then(({ data }) => {
			setCompleted(true);
			setShowModal(false);
			fetchJobs();
		});
	}, [fetchJobs, modalJob]);


	return (
		<div className="page myorders">
			<h1>My Orders</h1>

			<Loader show={fetching} />
			<Completed show={completed} setShow={setCompleted} duration="750" />

			<BaseModal show={showModal === "completed"} onClose={() => setShowModal(false)} className="center">
				<h2>Complete this job?</h2>
				<p>{modalJob?.title}</p>
				<button onClick={sendCompleteJob} className="base-button">Yes, complete</button>
			</BaseModal>

			<BaseModal show={showModal === "archive"} onClose={() => setShowModal(false)} className="center">
				<h2>Archive this job?</h2>
				<p>{modalJob?.title}</p>
				<button onClick={sendArchiveJob} className="base-button">Yes, archive</button>
			</BaseModal>

			<BaseModal show={showModal === "delete"} onClose={() => setShowModal(false)} className="center">
				<h2>Delete this job?</h2>
				<p>{modalJob?.title}</p>
				<button onClick={sendDeleteJob} className="base-button">Yes, delete</button>
			</BaseModal>


			<ReviewModal show={showModal === "review"} onClose={() => setShowModal(false)} job={modalJob} onCompleted={() => { setCompleted(true); fetchJobs(); }} />


			<BaseTabs tabs={Tabs} counts={tabsCount} onTab={setCurrentTab} current={currentTab} sticky>
				{!tabJobs.length && (
					<div className="empty"><span>{currentTab}</span> list is empty</div>
				)}
				{tabJobs.map(job => (
					<div className="job" key={job._id}>
						<div className="title">
							<Link to={`/jobs/${job._id}`}>{job.title}</Link>
						</div>

						<div className="info">
							<div className="cols">
								<div className="block">
									<div className="name">Status</div>
									<div className="value">{job.status}</div>
								</div>

								<div className="block">
									<div className="name">Accepted by</div>
									<div className="value">{job.executor?.name || "None"}</div>
								</div>

								<div className="block">
									<div className="name">Total bids</div>
									<div className="value">{job.bids.length} bids</div>
								</div>

								<div className="block">
									<div className="name">New bids</div>
									<div className="value">{job.new_bids} bids</div>
								</div>
							</div>

							<div className="buttons">
								{job.status === "working" && (
									<button className="btn text" onClick={() => { setModalJob(job); setShowModal("completed") }}>
										<img src={IconCompleted} alt="Completed" title="Completed" />
										<span>Complete</span>
									</button>
								)}

								<Link className="btn text" to={`/jobs/${job._id}/bids`}>
									<img src={IconBids} alt="Bids" title="Show bids" />
									<span>Show bids</span>
								</Link>

								{job.status === "completed" && (
									<button className="btn text" onClick={() => { setModalJob(job); setShowModal("review") }} disabled={!!job.review}>
										<img src={IconReview} alt="Review" title="Review" />
										<span>{!!job.review ? "Reviewed" : "Add review"}</span>
									</button>
								)}

								{job.status === "active" && (
									<button className="btn text" onClick={() => { setModalJob(job); setShowModal("archive") }}>
										<img src={IconArchive} alt="Archive" title="Archive job" />
										<span>Archive job</span>
									</button>
								)}

								{job.status === "active" && (
									<>
										<Link to={`/jobs/${job._id}/edit`} className="btn">
											<img src={IconEdit} alt="Edit" title="Edit job" />
										</Link>

										<button className="btn" onClick={() => { setModalJob(job); setShowModal("delete") }}>
											<img src={IconDelete} alt="Delete" title="Delete job" />
										</button>
									</>
								)}
							</div>
						</div>

					</div>
				))}
			</BaseTabs>
		</div>
	)
};

export default observer(MyOrders);