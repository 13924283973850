import axios from "axios";
import { useCallback, useState } from "react";
import { parseError } from "utils/helpers";

import Completed from "components/Base/Completed";

const TabPassword = () => {
	const [currentPassword, setCurrentPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [repeatPassword, setRepeatPassword] = useState("");

	const [isSaved, setIsSaved] = useState(false);
	const [error, setError] = useState({});

	const saveChanges = useCallback((e) => {
		e.preventDefault();

		if (!currentPassword || !newPassword || !repeatPassword) {
			return setError({ type: "Error", message: "not all fields has filled" });
		}

		if (newPassword !== repeatPassword) {
			return setError({ type: "Error", message: "passwords do not match" });
		}

		setError({});

		axios.patch("/profile/password", {
			current_password: currentPassword,
			new_password: newPassword
		}).then(() => {
			setIsSaved(true);
		}).catch(error => {
			setError(parseError(error));
		});
	}, [currentPassword, newPassword, repeatPassword]);

	return (
		<form autoComplete="off" className="edit-tab password" onSubmit={saveChanges}>
			<div className="base-label">Current password</div>
			<input type="password" className="base-input" value={currentPassword} onChange={e => setCurrentPassword(e.target.value)} />

			<div className="base-label">New password</div>
			<input type="password" className="base-input" value={newPassword} onChange={e => setNewPassword(e.target.value)} />

			<div className="base-label">Repeat password</div>
			<input type="password" className="base-input" value={repeatPassword} onChange={e => setRepeatPassword(e.target.value)} />

			<div className="bottom">
				<button className="save" onClick={saveChanges}>Save changes</button>
				{error.message && (
					<div className="error"><b>{error.type}:</b> {error.message}</div>
				)}
			</div>

			<Completed show={isSaved} setShow={setIsSaved} />
		</form>
	)
};

export default TabPassword;