import { Categories } from "utils/constants";
import BaseCheckbox from "components/Base/BaseCheckbox";

// import IconCategory from "./img/cat-icon.svg";
// import IconArrowUp from "./img/cat-arrow-up.svg";
// import IconArrowDown from "./img/cat-arrow-down.svg";


const CategoriesPicker = ({ selectCategory, selected = [] }) => {
	return (
		<div className="categories">
			{Categories.map(category => (
				<div className="item" key={category}>
					<BaseCheckbox
						onChange={() => selectCategory(category)}
						checked={selected.includes(category)}
						text={category}
					/>
				</div>
			))}
		</div>
	)
};

export default CategoriesPicker;