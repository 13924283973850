const ForProviders = () => {
	return (
		<div className="page policy about">
			<h1>Caregivers Providing Respite</h1>

			<p>Emissary is a platform designed to complement your current workload, and offer you four core things:</p>

			<div className="box blue"><span>1</span> Opportunities to increase your annual income </div>
			<div className="box blue"><span>2</span> Schedule flexibility to work around your existing commitments </div>
			<div className="box blue"><span>3</span> Autonomy in deciding your own rate of pay and tasks to complete</div>
			<div className="box blue"><span>4</span> Verification of your quality of work </div>

			<p>We believe these fundamentals will enable your long term personal health and success, which is important for us all. You are an essential part of the healthcare system, providing valuable services and support to improve overall health outcomes and quality of life.</p>

			<p>Thank you for your work every day. Thank you for considering joining us at Emissary.</p>
		</div>
	);
};

export default ForProviders;