import axios from "axios";
import { makeAutoObservable } from "mobx";

class Auth {
	showModal = false;
	isAuth = false;
	isSocketAuth = false;
	token = null;

	user = {
		id: null,
		type: null,
		name: null,
		surname: null,
		avatar: null,
		paypal: null,
		isProvider: false,
		isCustomer: false
	};

	constructor() {
		makeAutoObservable(this);

		const savedToken = localStorage.getItem("token");
		const userType = localStorage.getItem("userType");
		if (savedToken) {
			this.authorize(savedToken, userType);
		}
	}

	*fetchUserInfo() {
		try {
			const { data } = yield axios.get("/auth/user");
			this.user = {
				id: data.id,
				type: data.type,
				name: data.name,
				surname: data.surname,
				avatar: data.avatar,
				paypal: data.paypal,
				isProvider: data.type === "provider",
				isCustomer: data.type === "customer"
			};
		}
		catch (e) {
			if (e.response?.status === 401) {
				this.unauthorize();
				console.log("Bad token. Unauthorized");
			}
		}
	}

	changeNames(name, surname) {
		if (this.user.name !== name) this.user.name = name;
		if (this.user.surname !== surname) this.user.surname = surname;
	}

	changeAvatar(avatar) {
		this.user.avatar = avatar;
	}

	toggleModal(show) {
		this.showModal = show ? show : !this.showModal;
	}

	authorize(token, type) {
		console.log(`Authorized by ${type}`);
		this.showModal = false;
		this.token = token;
		this.isAuth = true;
		this.user.type = type;
		this.user.isCustomer = type === "customer";
		this.user.isProvider = type === "provider";
		localStorage.setItem("token", token);
		localStorage.setItem("userType", type);
		axios.defaults.headers.authorization = `Bearer ${token}`;
	}

	socketAuhorize(status) {
		this.isSocketAuth = status;
	}

	unauthorize() {
		console.log("Unauthorized");
		this.showModal = false;
		this.token = null;
		this.isAuth = false;
		localStorage.removeItem("token");
		localStorage.removeItem("userType");
		delete axios.defaults.headers.authorization;

		this.user = {
			id: null,
			name: null,
			surname: null,
			type: null,
			isProvider: false,
			isCustomer: false
		};
	}
};

export default new Auth();