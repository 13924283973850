import axios from "axios";
import { useCallback, useState } from "react";
import { errorNotification } from "utils/helpers";

import BaseEdit from "components/Base/BaseEdit";
import BaseModal from "components/Base/BaseModal";
import Completed from "components/Base/Completed";

import IconAvatar from "./avatar.svg";

const MessageModal = ({ show, onClose, user }) => {
	const [text, setText] = useState("");
	const [completed, setCompleted] = useState(false);

	const onSend = useCallback(() => {
		if (text.length < 5) {
			return errorNotification("Review text is required. Minimum length - 5 characters");
		};

		axios.post(`/profile/${user._id}/message`, {
			content: text
		}).then(({ data }) => {
			console.log(data);
			setText("");
			setCompleted(true);
		});
	}, [user, text]);

	return (
		<BaseModal show={show} onClose={onClose} className="message-popup">
			<div className="info">
				<div className="avatar">
					<img src={IconAvatar} alt="Avatar" />
				</div>

				<div className="about">
					<div className="name">Message for {user?.name} {user?.surname}</div>
					<div className="title">{user?.location?.city}</div>
				</div>
			</div>

			<BaseEdit value={text} onChange={setText} />

			<div className="bottom">
				<button className="base-button" onClick={onSend}>Send message</button>
			</div>

			<Completed show={completed} setShow={setCompleted} onHide={onClose} />
		</BaseModal>
	)
};

export default MessageModal;