import moment from "moment";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";

import IconArrow from "./img/arrow-slider.svg";

const generateDates = (days = 10) => {
	const dates = [];

	let date = moment();
	date.hours(12);
	date.minutes(0);
	date.seconds(0);
	date.milliseconds(0);

	while (days > 0) {
		dates.push({
			month: date.format("MMMM"),
			date: date.format("DD"),
			day: date.format("dddd"),
			iso: date.toISOString()
		});

		date.add(1, "day");
		days -= 1;
	}

	return dates;
};


const DATES = generateDates(40);


const DatesSlider = ({ onChange, selected = "" }) => {
	const [page, setPage] = useState(0);
	const [maxPages, setMaxPages] = useState(0);

	useEffect(() => {
		const max = Math.floor(DATES.length / 5) - 1;
		setMaxPages(max);
	}, []);

	const changePage = useCallback((val) => {
		let newPage = page + val;
		if (newPage < 0) newPage = 0;
		if (newPage > maxPages) newPage = maxPages;
		setPage(newPage);
	}, [page, maxPages]);

	useEffect(() => {
		const idx = DATES.findIndex(d => d.iso === selected);
		if (idx) setPage(Math.floor(idx / 5));
	}, [selected]);

	const canLeft = useMemo(() => page > 0, [page]);
	const canRight = useMemo(() => page < maxPages, [page, maxPages]);

	return (
		<div className="dates-slider">
			<div className="overflow">
				<div className="container" style={{ transform: `translateX(-${page * 100}%)` }}>
					{DATES.map(({ month, date, day, iso }, index) => (
						<div
							className={classNames("item", { selected: selected === iso })}
							onClick={() => onChange(iso)}
							key={index}
						>
							<div className="month">{month}</div>
							<div className="date">{date}</div>
							<div className="day">{day}</div>
						</div>
					))}
				</div>
			</div>

			<img
				src={IconArrow}
				className={classNames("arrow left", { disabled: !canLeft })}
				alt="Move left"
				onClick={() => changePage(-1)}
			/>

			<img
				src={IconArrow}
				className={classNames("arrow right", { disabled: !canRight })}
				alt="Move right"
				onClick={() => changePage(1)}
			/>
		</div>
	)
};

export default DatesSlider;