import axios from "axios";
import moment from "moment";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useStore } from "store";
import { getAvatar } from "utils/helpers";

import Loader from "components/Base/Loader";
import MessageModal from "components/MessageModal/MessageModal";
import ProviderDetails from "./ProviderDetails";
import CustomerDetails from "./CustomerDetails";

import IconLocation from "./img/location.svg";
import IconReviews from "./img/reviews.svg";
import IconStared from "./img/stared.png";
// import IconUser from "./img/user.svg";
// import IconUnstared from "./img/unstared.png";

const Profile = () => {
	const [user, setUser] = useState(null);
	const [showMessagePopup, setShowMessagePopup] = useState(false);

	const store = useStore();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { id } = useParams();

	useEffect(() => {
		const type = pathname.split("/")[1].slice(0, -1);

		if (id && type) {
			setUser(null);

			axios.get(`/profile/${type}/${id}`).then(({ data }) => {
				setUser({ ...data, type });
			}).catch(error => {
				navigate("/");
			});
		}
	}, [id, pathname, navigate]);


	const rating = useMemo(() => {
		if (!user) return {};

		const total = user.reviews.length;
		const average = user.reviews.reduce((acc, i) => acc + i.rate, 0) ?? 0 / total;

		return {
			total,
			average
		};
	}, [user]);


	if (!user) return <Loader />

	return (
		<div className="page profile">
			<div className="sides">
				<div className="left">
					<div className="profile-user">
						<div className="avatar">
							<img src={getAvatar(user.avatar)} alt="User" />
						</div>

						<div className="info">
							<div className="name big">{user.name} {user.surname}</div>
							{user.location?.city && (
								<div className="location">
									<img src={IconLocation} alt="Location" />
									<span>{user.location.city || "Undefined"}</span>
									{user.location.zip && (
										<span>, {user.location.zip}</span>
									)}
								</div>
							)}
						</div>

						{user._id === store.auth.user.id && (
							<Link className="button" to="/profile">Edit profile</Link>
						)}
					</div>

					<div className="more">
						<div className="description">
							{user.about}
						</div>

						{user.type === "provider" && user.tags?.length > 0 && (
							<div className="tags">
								{user.tags.map(tag => (
									<div className="item" key={tag}>{tag}</div>
								))}
							</div>
						)}
					</div>

					{user.type === "provider" && (
						<ProviderDetails user={user} />
					)}

					{user.type === "customer" && (
						<CustomerDetails user={user} />
					)}
				</div>

				<div className="right">
					<div className="profile-summary">
						<div className="reviews">
							<div className={classNames("percent", { hide: !rating.average })}>{rating.average.toFixed(1)}</div>
							<div className="count">
								<div className="title">Total reviews</div>
								<div className="value">{rating.total} reviews</div>
							</div>
							<img src={IconReviews} alt="Reviews" />
						</div>

						<div className="registration">
							<div className="title">Date of registration</div>
							<div className="date">{moment(user.date_create).format("MM/DD/YYYY")}</div>
						</div>

						<div className="rating">
							<div className="stars">
								{Array(5).fill(null).map((i, index) => (
									<img src={IconStared} className={classNames("star", { opacity: rating.average <= index })} alt="Star" key={index} />
								))}
							</div>

							{/* <div className="count">{rating.average} <span>({rating.count})</span></div> */}
						</div>
					</div>

					{/* {user.rate && (
						<div className="rate">
							<div className="title">Hourly Rate</div>
							<div className="value">{user.rate}$ <small>/ hour</small></div>
						</div>
					)} */}

					{user.type === "provider" && store.auth.user.isCustomer && user._id !== store.auth.user.id && (
						<>
							{/* <button className="hire">Get in Touch</button> */}
							<button className="message" onClick={() => setShowMessagePopup(true)}>Message for {user.name}</button>
						</>
					)}

					{/* {user.type === "customer" && store.auth.user.isProvider && user._id !== store.auth.user.id && (
						<>
							<button className="message" onClick={() => setShowMessagePopup(true)}>Message for {user.name}</button>
						</>
					)} */}
				</div>
			</div>


			<MessageModal show={showMessagePopup} onClose={() => setShowMessagePopup(false)} user={user} />
		</div>
	)
};

export default observer(Profile);