import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { parseError } from "utils/helpers";
import { useStore } from "../../store";

import BaseEdit from "components/Base/BaseEdit";
import Completed from "components/Base/Completed";
import Loader from "components/Base/Loader";

const TabGeneral = () => {
	const avatarPrev = useRef();
	const avatarInput = useRef();
	const [avatarChanged, setAvatarChanged] = useState(false);
	const [name, setName] = useState("");
	const [surname, setSurname] = useState("");
	const [city, setCity] = useState("");
	const [zip, setZip] = useState("");
	const [street, setStreet] = useState("");
	const [about, setAbout] = useState("");

	const [changes, setChanges] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isSaved, setIsSaved] = useState(false);
	const [error, setError] = useState({});

	const store = useStore();

	useEffect(() => {
		axios.get("/profile/general").then(({ data }) => {
			setName(data.name);
			setSurname(data.surname);
			if (data.location) {
				setCity(data.location.city);
				setZip(data.location.zip);
				setStreet(data.location.street);
			}
			if (data.about) setAbout(data.about);
		});
	}, []);

	useEffect(() => {
		if (name || surname || city || zip || street || about) {
			setChanges(prev => {
				return prev === null ? false : true;
			});
		}
	}, [name, surname, city, zip, street, about]);


	// Only on first page loading
	useEffect(() => {
		if (store.auth.user.avatar && !avatarPrev.current) {
			avatarPrev.current = store.auth.user.avatar;
		}
	}, [store.auth.user.avatar]);

	// Only once on unmount
	useEffect(() => {
		return () => {
			store.auth.changeAvatar(avatarPrev.current);
		};
	}, [store.auth]);

	const onAvatarChange = useCallback((event) => {
		if (event.target.files.length) {
			const file = event.target.files[0];
			const image = URL.createObjectURL(file);
			store.auth.changeAvatar(image);
			setAvatarChanged(true);
		}
	}, [store.auth]);

	const saveAvatar = useCallback(() => {
		const file = avatarInput.current.files[0];
		const form = new FormData();
		form.append("avatar", file);

		setIsLoading(true);

		axios.patch("/profile/avatar", form).then(({ data }) => {
			avatarPrev.current = data.avatar;
			avatarInput.current.value = "";
			store.auth.changeAvatar(data.avatar);
			setAvatarChanged(false);
			setIsSaved(true);
		}).finally(() => {
			setIsLoading(false);
		});
	}, [store.auth]);

	const saveChanges = useCallback(() => {
		setError({});
		setIsLoading(true);

		axios.patch("/profile/general", {
			name, surname, location: { city, zip, street }, about
		}).then(({ data }) => {
			setIsSaved(true);
			setChanges(false);
			store.auth.changeNames(name, surname);
		}).catch(error => {
			console.log(error);
			setError(parseError(error));
		}).finally(() => {
			setIsLoading(false);
		});
	}, [name, surname, city, zip, street, about, store.auth]);

	return (
		<div className="edit-tab general">
			<div className="base-label">Avatar</div>
			<div className="avatar">
				<input type="file" className="base-input" ref={avatarInput} onChange={onAvatarChange} />
				<button className="base-button upload" disabled={!avatarChanged} onClick={saveAvatar}>Upload</button>
			</div>


			<div className="base-label">Name</div>
			<input type="text" className="base-input" value={name} onInput={e => setName(e.target.value)} />

			<div className="base-label">Surname</div>
			<input type="text" className="base-input" value={surname} onInput={e => setSurname(e.target.value)} />

			<div className="flex">
				<div className="w64">
					<div className="base-label">City</div>
					<input type="text" className="base-input" value={city} onInput={e => setCity(e.target.value)} />
				</div>

				<div className="w34">
					<div className="base-label">ZIP</div>
					<input type="text" className="base-input" value={zip} onInput={e => setZip(e.target.value)} />
				</div>
			</div>

			<div className="base-label">Street</div>
			<input type="text" className="base-input" value={street} onInput={e => setStreet(e.target.value)} />

			<div className="base-label">About me</div>
			<BaseEdit value={about} onChange={setAbout} />

			<p className="after-about">Your profile is your way of presenting yourself to potential caregivers that are going to work with you. This will help ensure you find the right match for your needs.</p>

			<div className="bottom">
				<button className="save" onClick={saveChanges} disabled={!changes}>Save changes</button>
				{error.message && (
					<div className="error"><b>{error.type}:</b> {error.message}</div>
				)}
			</div>

			<Loader show={isLoading} />
			<Completed show={isSaved} setShow={setIsSaved} />
		</div>
	)
};

export default TabGeneral;