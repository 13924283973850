import axios from "axios";
import classNames from "classnames";
import { useCallback, useState } from "react";
import { errorNotification, parseError } from "utils/helpers";

import BaseModal from "components/Base/BaseModal";
import BaseEdit from "components/Base/BaseEdit";

import IconAvatar from "./img/avatar.svg";
import IconStared from "pages/Profile/img/stared.png";

const ReviewModal = ({ show, job, onClose, onCompleted }) => {
	const [text, setText] = useState("");
	const [rate, setRate] = useState(3);

	const sendReview = useCallback(() => {
		if (text.length < 5) {
			return errorNotification("Review text is required. Minimum length - 5 characters");
		};

		axios.post(`/jobs/${job._id}/review`, { rate, text }).then(() => {
			onClose();
			onCompleted();
		}).catch(e => {
			errorNotification(parseError(e));
		});
	}, [rate, text, job, onClose, onCompleted]);

	return (
		<BaseModal show={show} onClose={onClose} className="review">
			<div className="info">
				<div className="avatar">
					<img src={IconAvatar} alt="Avatar" />
				</div>

				<div className="about">
					<div className="name">Review for {job?.executor?.name}</div>
					<div className="job">{job?.location?.city} - {job?.title}</div>
				</div>
			</div>

			<div className="rating">
				<div className="title">Rate</div>
				<div className="value">{rate} / 5</div>
				<div className="stars">
					{Array(5).fill(null).map((i, index) => (
						<img
							src={IconStared}
							key={index}
							className={classNames("star", { unstared: rate <= index })}
							onClick={() => setRate(index + 1)}
							alt="Star"
						/>
					))}
				</div>
			</div>

			<BaseEdit value={text} onChange={setText} />

			<div className="bottom">
				<button className="base-button" onClick={sendReview}>Send review</button>
			</div>
		</BaseModal>
	)
};

export default ReviewModal;