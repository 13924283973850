import classNames from "classnames";
import { Link } from "react-router-dom";

import IconStar from "./img/stared.png";

const Review = ({ data }) => {
	return (
		<div className="review item">
			<div className="info">
				<div className="left">
					<Link to={`/jobs/${data.job._id}`} className="title">{data.job.title}</Link>
					<Link to={`/${data.creatorType}/${data.creator._id}`} className="subtitle">
						{data.job.location.city} - {data.creator.name} {data.creator.surname}
					</Link>
				</div>
				<div className="right">
					{Array(5).fill(null).map((i, index) => (
						<img src={IconStar} className={classNames("star", { opacity: data.rate <= index })} alt="Star" key={index} />
					))}
					<div className="rate">{data.rate.toFixed(1)}</div>
				</div>
			</div>

			<div className="description">{data.text}</div>
		</div>
	)
};

export default Review;