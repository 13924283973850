import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";

import socket from "services/socket";
import { dateFromNow, getAvatar } from "utils/helpers";
import { useStore } from "../../store";

// import IconChatUser from "./img/user.svg";
import IconChats from "./img/chats.png";


const Chats = () => {
	const [chats, setChats] = useState([]);
	const [currentChat, setCurrentChat] = useState(null);
	const [messages, setMessages] = useState([]);
	const messagesContainer = useRef();

	const store = useStore();

	useEffect(() => {
		if (store.auth.isSocketAuth) {
			socket.emit("reqChats");
		}
	}, [store.auth.isSocketAuth]);

	useEffect(() => {
		const onResChats = (data) => {
			setChats(data);
		};

		const onResDialog = (data) => {
			setMessages(data);
		};

		const onNewMessage = ({ chat, message }) => {
			if (currentChat === chat) {
				setMessages(prev => [...prev, message]);
				if (!message.isMe) {
					socket.emit("makeReaded", chat);
				}
			}

			setChats(prev => prev.map(item => {
				if (item._id === chat) {
					if (currentChat !== chat && !message.isMe) {
						item.unreaded += 1;
					}
					item.message = message;
				}
				return { ...item };
			}));
		};

		const onChatReaded = ({ chat, from }) => {
			if (chat === currentChat) {
				setMessages(prev => prev.map(item => {
					if (item.from === from) item.isReaded = true;
					return { ...item };
				}));
			}
		};

		const onNewChat = () => {
			socket.emit("reqChats");
		};

		socket.on("newChat", onNewChat);
		socket.on("resChats", onResChats);
		socket.on("resDialog", onResDialog);
		socket.on("newMessage", onNewMessage);
		socket.on("chatReaded", onChatReaded);

		return () => {
			socket.off("newChat", onNewChat);
			socket.off("resChats", onResChats);
			socket.off("resDialog", onResDialog);
			socket.off("newMessage", onNewMessage);
			socket.off("chatReaded", onChatReaded);
		}
	}, [currentChat]);

	const scrollMessages = useCallback(() => {
		if (messagesContainer.current) {
			messagesContainer.current.scrollTop = messagesContainer.current.scrollHeight;
		}
	}, []);

	useEffect(() => {
		scrollMessages();
	}, [messages, scrollMessages]);

	useEffect(() => {
		const interval = setInterval(() => {
			console.log("Update messages time");
			setMessages(prev => [...prev]);
			setChats(prev => [...prev]);
		}, 60000);

		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		const hasUnreaded = chats.some(chat => chat.unreaded > 0);
		store.notif.setUnreadedChats(hasUnreaded);
	}, [store, chats]);

	const toggleChat = useCallback((id) => {
		setCurrentChat(prev => prev === id ? null : id);
		setMessages([]);

		if (id) {
			socket.emit("reqDialog", id);

			setChats(prev => prev.map(chat => {
				if (chat._id === id) chat.unreaded = 0;
				return { ...chat };
			}));
		}
	}, []);

	const sendMessage = useCallback((event) => {
		event.preventDefault();
		const content = event.target.content.value;
		event.target.content.value = "";

		socket.emit("sendMessage", { chat: currentChat, content });
	}, [currentChat]);



	return (
		<div className="page chats">
			<div className="dialogs">
				<h2>All chats</h2>

				{chats.map((chat) => (
					<div className={classNames("item", { current: currentChat === chat._id })} onClick={() => toggleChat(chat._id)} key={chat._id}>
						<img src={getAvatar()} alt="Avatar" className="avatar" />
						<div className="info">
							<div className="flex">
								<div className="name">{chat.companion.name} {chat.companion.surname}</div>
								<div className="date">{dateFromNow(chat.message?.date_create)}</div>
							</div>
							<div className="flex bottom">
								<div className="message">{chat.message?.content}</div>
								{chat.unreaded > 0 && (
									<div className="budge">{chat.unreaded}</div>
								)}
							</div>
						</div>
					</div>
				))}
			</div>

			{currentChat === null && (
				<div className="chat center">
					<div className="empty">
						<img src={IconChats} alt="Choose a chat" />
						<div className="title">Choose a chat</div>
					</div>
				</div>
			)}

			{!!currentChat && (
				<div className="chat single">
					<div className="messages base-scroll" ref={messagesContainer}>
						{messages.map((item) => (
							<div className={classNames("item", { my: item.isMe })} key={item._id}>
								<img src={getAvatar()} alt="Avatar" className="avatar" />
								<div className="content">
									<div className="cloud">{item.content}</div>
									<div className="info">
										<span className="date">{dateFromNow(item.date_create)}</span>
										<span>{item.isReaded ? "Viewed" : "Unreaded"}</span>
									</div>
								</div>
							</div>
						))}
					</div>

					<form className="bottom" onSubmit={sendMessage}>
						<input name="content" type="text" placeholder="Your message" />
						<button>Send</button>
					</form>
				</div>
			)}
		</div>
	)
};


export default observer(Chats);