import { makeAutoObservable, observable } from "mobx";

class Notifications {
	array = [];
	unreadedChats = false;

	constructor() {
		makeAutoObservable(this);
	}

	setUnreadedChats(value) {
		this.unreadedChats = value;
	}

	create({ title, content, type, date, autoClose = 5000 }) {
		const notification = observable({
			id: Date.now(),
			type: type || "info",
			title: title || null,
			content: content,
			date: date || null,
			hide: false
		});

		this.array.push(notification);

		if (autoClose) {
			setTimeout(() => {
				this.hide(notification.id);
			}, autoClose);
		}
	}

	hide(id) {
		const item = this.array.find(i => i.id === id);

		if (item) {
			item.hide = true;

			setTimeout(() => {
				this._remove(id);
			}, 525);
		}
	}

	_remove(id) {
		const index = this.array.findIndex(i => i.id === id);
		this.array.splice(index, 1);
	}
}

export default new Notifications();