const Policy = () => {
	return (
		<div className="page policy">
			<h1>Privacy Policy</h1>
			<div className="update">Last update: February 23, 2022</div>

			<h2>The long version</h2>
			<p><b>Information We Collect</b> - Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop.</p>

			<p><b>About information</b> - Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop. Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta unde, aut perferendis suscipit minus cumque reprehenderit iure modi veniam magni quis adipisci deserunt, dolore nihil, illum et molestiae harum ea. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi, pariatur nisi officia asperiores corrupti consequuntur quam, impedit optio magnam odit eaque sunt veniam quod qui tempore eligendi, dolorem nesciunt nihil?</p>

			<p><b>How Do We Use the Information Collected</b> - Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop.</p>

			<h2>The short version</h2>

			<p><b>Information We Collect</b> - Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop.</p>

			<p><b>How Do We Use the Information Collected</b> - Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
		</div>
	)
};

export default Policy;