import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Categories } from "utils/constants";
import { parseError } from "utils/helpers";

import BaseEdit from "components/Base/BaseEdit";
import Completed from "components/Base/Completed";
import Dropdown from "components/Base/Dropdown";

import IconCategory from "./img/category.svg";
import IconRemove from "./img/delete.png";

const TabProvider = () => {
	const [primaryLang, setPrimaryLang] = useState("");
	const [secondaryLang, setSecondaryLang] = useState("");
	const [resume, setResume] = useState("");
	const [cost, setCost] = useState([]);
	const [tags, setTags] = useState([]);

	const [saved, setSaved] = useState(false);
	const [error, setError] = useState({});

	const restCategories = useMemo(() => {
		return Categories.filter(cat => !tags.includes(cat));
	}, [tags]);

	useEffect(() => {
		axios.get("/profile/workinfo").then(({ data }) => {
			if (data.resume) setResume(data.resume);
			if (data.cost) setCost(data.cost);
			if (data.tags) setTags(data.tags);
			if (data.languages?.primary) setPrimaryLang(data.languages.primary);
			if (data.languages?.secondary) setSecondaryLang(data.languages.secondary);
		}).catch(error => {
			setError(parseError(error));
		});
	}, []);

	const changeCost = useCallback((e) => {
		const [type, index] = e.target.name.split("-");
		setCost(prev => {
			prev[index][type] = e.target.value;
			return [...prev];
		});
	}, []);

	const addCost = useCallback(() => {
		setCost(prev => [...prev, { title: "", price: "" }]);
	}, []);

	const removeCost = useCallback((index) => {
		setCost(prev => prev.filter((_, i) => i !== index));
	}, []);

	const addTag = useCallback((tag) => {
		setTags(prev => [...prev, tag]);
	}, []);

	const removeTag = useCallback((tag) => {
		setTags(prev => prev.filter((t) => t !== tag));
	}, []);

	const saveChanges = useCallback(() => {
		const pureCost = cost.filter(c => c.title && c.price);
		setError({});

		axios.patch("/profile/workinfo", {
			resume,
			cost: pureCost,
			tags,
			languages: {
				primary: primaryLang,
				secondary: secondaryLang
			}
		}).then(() => {
			setSaved(true);
		}).catch(error => {
			setError(parseError(error));
		});
	}, [resume, cost, tags, primaryLang, secondaryLang]);

	return (
		<div className="edit-tab work">
			{/* <div className="base-label">My hourly rate</div>
			<div className="rate">
				<input type="number" min="0" max="100000" step="1" className="base-input" value={rate} onChange={e => setRate(e.target.value)} />
				<span>$/h</span>
			</div> */}


			<div className="base-label">Experience and skills</div>
			<BaseEdit value={resume} onChange={setResume} placeholder="Please provide any relevant information" maxLength={2000} rows={10} />

			<div className="flex">
				<div className="w48">
					<div className="base-label">Primary language</div>
					<input type="text" className="base-input" value={primaryLang} onInput={e => setPrimaryLang(e.target.value)} />
				</div>

				<div className="w48">
					<div className="base-label">Secondary language</div>
					<input type="text" className="base-input" value={secondaryLang} onInput={e => setSecondaryLang(e.target.value)} />
				</div>
			</div>

			<div className="category-title">
				<span>Cost of work</span>
				<button className="add base-button" onClick={addCost}>Add line</button>
			</div>
			<div className="list">
				{!cost.length && (
					<div className="length">You have not added any cost yet</div>
				)}

				{cost.map((item, index) => (
					<div className="item" key={index}>
						<div className="title">
							<input type="text" placeholder="Work name" className="base-input" value={item.title} name={`title-${index}`} onChange={changeCost} />
						</div>

						<div className="price">
							<input type="number" min="1" max="100000" step="1" className="base-input" placeholder="$" name={`price-${index}`} value={item.price} onChange={changeCost} />
						</div>

						<div className="remove base-button" onClick={() => removeCost(index)}>
							<img src={IconRemove} alt="Remove" />
						</div>
					</div>
				))}
			</div>

			<div className="category-title">
				<span>Category</span>

				<Dropdown autoclose items={restCategories} onChange={addTag}>
					<button className="add base-button">Add category</button>
				</Dropdown>
			</div>
			<div className="list">
				{!tags.length && (
					<div className="length">You must select at least one category</div>
				)}

				{tags.map((tag, index) => (
					<div className="item category" key={tag}>
						<div className="title base-input">
							<img src={IconCategory} alt="Category" />
							<span>{tag}</span>
						</div>
						<div className="remove base-button" onClick={() => removeTag(tag)}>
							<img src={IconRemove} alt="Remove" />
						</div>
					</div>
				))}


			</div>

			<div className="bottom">
				<button className="save" onClick={saveChanges}>Save changes</button>
				{error.message && (
					<div className="error"><b>{error.type}:</b> {error.message}</div>
				)}
			</div>

			<Completed show={saved} setShow={setSaved} />
		</div>
	)
};

export default TabProvider;