const AboutUs = () => {
	return (
		<div className="page policy about">
			<h1>About Us</h1>

			<p><b>Emissary</b> is an online platform for finding respite care. Respite care is a type of short-term care that is designed to give a break to caregivers who need time to rest, attend to personal business, or take care of their own health needs. It can also help prevent caregiver burnout and allow caregivers to continue providing high-quality care over the long term. Emissary strives to connect individuals and families with caregivers who have the necessary cultural competencies and skills to provide support that is responsive to their cultural beliefs, values, and practices. We aim to help address social determinants of health by ensuring access to care that is tailored to specific needs and preferences.</p>

			<p>Some ways in which we work to create value for customers are:</p>

			<div className="box info">
				<div className="title"><span>1</span> Improving access to care:</div>
				<div className="desc">By providing a platform for individuals and families to connect with caregivers who understand their cultural backgrounds, a caregiver marketplace can help overcome barriers to accessing care that arise from language differences, mistrust of the healthcare system, or lack of familiarity with available resources.</div>
			</div>

			<div className="box info">
				<div className="title"><span>2</span> Reducing health disparities:</div>
				<div className="desc">By being responsive to the needs of diverse populations, a caregiver marketplace can help reduce health disparities that are often driven by cultural differences and biases.</div>
			</div>

			<div className="box info">
				<div className="title"><span>3</span> Enhancing individual-centered care:</div>
				<div className="desc">By facilitating the matching of customers with caregivers who understand their needs and preferences, a caregiver marketplace can help promote care that is intentional and customized.</div>
			</div>

			<div className="box info">
				<div className="title"><span>4</span> Supporting community-based care:</div>
				<div className="desc">By connecting individuals and families with caregivers who are embedded in their communities, we can help support community-based care models that are often more effective at addressing the social determinants of health than traditional medical models.</div>
			</div>

			{/*<p>Get Started Today (Button - redirect to create a customer account)</p>*/}

		</div >
	);
};

export default AboutUs;