import { useEffect, useRef, useState } from "react";

const Confirmation = ({ setConfirm }) => {
	const [confirmCode, setConfirmCode] = useState(["", "", "", "", "", ""]);
	const inputs = useRef();

	useEffect(() => {
		setConfirm(confirmCode);
	}, [confirmCode, setConfirm]);

	useEffect(() => {
		inputs.current.children[0].focus();
	}, []);

	const enterConfirm = (target, index) => {
		const val = target.value.slice(-1).trim();
		if (!val || isNaN(val)) return;

		setConfirmCode(code => {
			code[index] = val;
			target.nextElementSibling?.focus();
			return [...code];
		});
	};

	const onPaste = (event) => {
		const val = event.clipboardData.getData("Text");
		if (isNaN(val) || val.length < 6 || val.length > 6) return;
		setConfirmCode(val.split(""));
		event.preventDefault();
		event.stopPropagation();
	};

	return (
		<div className="confirm-code">
			<p>Enter confirmation code</p>

			<div className="inputs" ref={inputs}>
				{Array(6).fill(null).map((a, index) => (
					<input type="text" onInput={(e) => enterConfirm(e.target, index)} value={confirmCode[index]} key={index} onPaste={onPaste} onPasteCapture={onPaste} />
				))}
			</div>
		</div>
	);
};

export default Confirmation;