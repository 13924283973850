import classNames from "classnames";

const Loader = ({ show = true, sticky = true }) => {
	return show && (
		<div className="loader">
			<div className={classNames("spiner", { sticky })}></div>
		</div>
	)
};

export default Loader;