import { useMemo } from "react";

const TagsSplit = ({ tags = [], max }) => {
	const tagsComputed = useMemo(() => {
		const array = [];
		let count = 0;
		let rest = tags.length;

		for (const tag of tags) {
			count += tag.length;
			if (count > max) break;

			array.push(tag);
			rest -= 1;
		}

		return { rest, array };
	}, [tags, max]);

	return (
		<div className="tags">
			{tagsComputed.array.map(tag => (
				<div className="item" key={tag}>{tag}</div>
			))}

			{!!tagsComputed.rest && (
				<div className="item">+{tagsComputed.rest}</div>
			)}
		</div>
	)
};

export default TagsSplit;