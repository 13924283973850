import moment from "moment";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useStore } from "store";

import IconInfo from "./img/info.png";

const Notifications = () => {
	const store = useStore();

	return (
		<div className="app-notifications">
			{store.notif.array.map((item) => (
				<div className={classNames("item", { hide: item.hide }, item.type)} key={item.id} onClick={() => store.notif.hide(item.id)}>
					<div className="icon">
						<img src={IconInfo} alt="Icon" />
					</div>
					<div className="message">
						{item.title && (
							<div className="title">{item.title}</div>
						)}
						<div className="content">{item.content}</div>
						{item.date && (
							<div className="date">{moment(item.date).format("DD.MM.YY - HH:mm:ss")}</div>
						)}
					</div>
				</div>
			))}
		</div>
	);
};

export default observer(Notifications);