import axios from "axios";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { parseError } from "utils/helpers";
import { useStore } from "../../store";

import Confirmation from "pages/Signup/Confirmation";
import GoogleSign from "components/GoogleSign/GoogleSign";
import Loader from "components/Base/Loader";

import IconClose from "./close.svg";

const Signin = () => {
	const [userType, setUserType] = useState("customer");
	const [isLoading, setIsLoading] = useState(false);
	const [isReset, setIsReset] = useState(false);
	const [resetStep, setResetStep] = useState(0);
	const [resetCode, setResetCode] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState({});

	const store = useStore();
	const show = store.auth.showModal;

	useEffect(() => {
		if (show) {
			setEmail("");
			setPassword("");
			setIsReset(false);
		}
	}, [show]);

	const hideModal = useCallback(() => {
		store.auth.toggleModal(false);
	}, [store.auth]);

	const overlayClose = useCallback((e) => {
		if (e.target.classList.contains("overlay")) {
			hideModal();
		}
	}, [hideModal]);

	const signIn = useCallback(() => {
		axios.post(`/auth/signin/${userType}`, { email, password }).then(({ data }) => {
			store.auth.authorize(data.token, userType);
		}).catch(e => {
			setError(parseError(e));
		});
	}, [email, password, userType, store.auth]);

	const startReset = useCallback(() => {
		setIsReset(true);
		setResetStep(0);
		setResetCode("");
	}, []);

	const resetPassword = useCallback(() => {
		setIsLoading(true);
		setError({});

		if (resetStep === 0) {
			axios.post(`/auth/reset/${userType}`, { email }).then(({ data }) => {
				store.notif.create({
					title: "Reset started",
					content: "An email has been sent to you to confirm your password reset. Check your email and enter the code from the letter in the code below",
					type: "info",
					autoClose: 15000
				});
				setResetStep(1);
				// setIsReset(false);
			}).catch(e => {
				setError(parseError(e));
			}).finally(() => {
				setIsLoading(false);
			});
		}

		if (resetStep === 1) {
			setResetStep(2);
			setIsLoading(false);
		}

		if (resetStep === 2) {
			axios.post(`/auth/reset/confirm`, { email, password, code: resetCode.join("") }).then(({ data }) => {
				setResetStep(2);
				setIsReset(false);

				store.notif.create({
					title: "Success",
					content: "Password reset successfully!",
					type: "success",
					autoClose: 10000
				});
			}).catch(e => {
				const status = e.response?.status;
				if (status === 422) {
					setResetStep(1);
					return store.notif.create({
						title: "Reset error",
						content: e.response.data.error,
						type: "error",
						autoClose: 10000
					});
				}
				else if (status === 426) {
					setResetStep(0);
					return store.notif.create({
						title: "Reset error",
						content: e.response.data.error,
						type: "error",
						autoClose: 10000
					});
				}
				else {
					setError(parseError(e));
				}
			}).finally(() => {
				setIsLoading(false);
			});
		}
	}, [store.notif, email, password, userType, resetCode, resetStep]);


	const googleSignIn = useCallback(({ access_token }) => {
		axios.post(`/auth/signin/${userType}/google`, { access_token }).then(({ data }) => {
			store.auth.authorize(data.token, userType);
		}).catch(e => {
			const message = e.response?.data?.error || "is invalid";
			setError({ type: "Google Auth", message });
		});
	}, [userType, store.auth]);

	return (
		<div className={classNames("overlay", { show })} onClick={overlayClose}>
			<div className="sign-component signin-modal">
				{!isReset ? (
					<div className="form">
						<h4>Sign In</h4>
						<div className="signin">Don’t have an account? <Link to="/" onClick={hideModal}>Sign up</Link></div>

						<div className="types">
							<div className={classNames("selector", { right: userType === "provider" })}></div>
							<div className="type" onClick={() => setUserType("customer")}>Customer</div>
							<div className="type" onClick={() => setUserType("provider")}>Caregiver</div>
						</div>

						<GoogleSign onSign={googleSignIn} type="in" />
						<div className="or"><span>or</span></div>

						<input type="email" placeholder="Email" onChange={e => setEmail(e.target.value)} value={email} />
						<input type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} password={password} />

						<div className="error-message">
							<b>{error.type}</b>
							{error.message}
						</div>

						<button className="submit" onClick={signIn}>Sign In</button>
						<div className="reset" onClick={startReset}>Forgot password?</div>
					</div>
				) : (
					<div className="form reset">
						<h4>Reset password</h4>
						<div className="signin">Don’t have an account? <Link to={`/auth/${userType}`} onClick={hideModal}>Sign up</Link></div>

						{resetStep === 0 && (
							<div className="types">
								<div className={classNames("selector", { right: userType === "provider" })}></div>
								<div className="type" onClick={() => setUserType("customer")}>Customer</div>
								<div className="type" onClick={() => setUserType("provider")}>Caregiver</div>
							</div>
						)}

						{resetStep === 0 && (
							<input type="email" placeholder="Email" onChange={e => setEmail(e.target.value)} value={email} />
						)}

						{resetStep === 1 && (
							<Confirmation setConfirm={setResetCode} />
						)}

						{resetStep === 2 && (
							<input type="password" placeholder="New password" onChange={e => setPassword(e.target.value)} password={password} />
						)}

						<div className="error-message">
							<b>{error.type}</b>
							{error.message}
						</div>

						<button className="submit" onClick={resetPassword}>{["Reset", "Enter", "Save"][resetStep]}</button>
						<div className="reset" onClick={() => setIsReset(false)}>Back to login</div>

						<Loader show={isLoading} />
					</div>
				)}


				<img src={IconClose} className="close" onClick={hideModal} alt="Close" />
			</div>
		</div>
	)
};

export default observer(Signin);