import axios from "axios";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { parseError } from "utils/helpers";

import BaseCheckbox from "components/Base/BaseCheckbox";
import BaseRadio from "components/Base/BaseRadio";
import Completed from "components/Base/Completed";
import DatesSlider from "./DatesSlider";
import TagsSelect from "./TagsSelect";

import IconLock from "pages/Home/img/lock.svg";
import IconUnlock from "pages/Home/img/unlock.svg";


const JobCreate = () => {
	const [tags, setTags] = useState([]);
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [date, setDate] = useState("");
	const [time, setTime] = useState(null);
	const [price, setPrice] = useState(100);
	const [suggestPrice, setSuggestPrice] = useState(false);

	const [location, setLocation] = useState({
		city: "",
		street: "",
		zip: ""
	});

	const [completed, setCompleted] = useState(false);
	const [error, setError] = useState({
		type: "",
		message: ""
	});

	const navigate = useNavigate();
	const { id: jobId } = useParams();

	useEffect(() => {
		if (jobId) {
			axios.get(`/jobs/${jobId}`).then(({ data }) => {
				setTags(data.tags);
				setTitle(data.title);
				setDescription(data.description);
				setDate(data.date);
				setTime(data.time);
				setPrice(data.price);
				setSuggestPrice(data.suggest_price);
				setLocation(data.location);
			}).catch(e => {
				navigate("/");
			});
		}
	}, [jobId, navigate]);

	const locationInput = useCallback((e) => {
		const { name, value } = e.target;
		setLocation(loc => ({
			...loc,
			[name]: value
		}));
	}, []);

	const selectTime = useCallback((e) => {
		const val = Number(e.target.value) || null;
		setTime(val);
	}, []);


	const submitJob = useCallback(async () => {
		setError({ type: "", message: "" });

		const body = {
			title,
			description,
			tags,
			date,
			time,
			price,
			suggest_price: suggestPrice,
			location
		};

		try {
			if (jobId) {
				await axios.patch(`/jobs/${jobId}`, body);
			}
			else {
				await axios.post("/jobs", body);
			}
			setCompleted(true);
		}
		catch (e) {
			setError(parseError(e));
		}
	}, [jobId, title, description, tags, date, time, price, suggestPrice, location]);


	return (
		<div className="page job-create">
			<h1>{jobId ? "Edit a Job" : "Create a Job"}</h1>

			<div className="block">
				<h2>Details</h2>

				<label className="base-label">What do you need done?</label>
				<input type="text" className="base-input" value={title} onInput={e => setTitle(e.target.value)} />

				<label className="base-label">Detailed message</label>
				<textarea className="base-input" rows="6" value={description} onInput={e => setDescription(e.target.value)}></textarea>
			</div>


			<div className="block">
				<h2>Location</h2>

				<div className="flex">
					<div className="w48">
						<label className="base-label mn">City</label>
						<input type="text" className="base-input" name="city" value={location.city} onInput={locationInput} />
					</div>
					<div className="w48">
						<label className="base-label mn">ZIP</label>
						<input type="text" className="base-input" name="zip" value={location.zip} onInput={locationInput} />
					</div>
				</div>

				<label className="base-label">Street</label>
				<input type="text" className="base-input" name="street" value={location.street} onInput={locationInput} />
			</div>


			<div className="block tags">
				<h2>Tags</h2>
				<TagsSelect onChange={setTags} init={tags} />
			</div>


			<div className="block">
				<h2>When do you need the job done?</h2>

				<DatesSlider onChange={setDate} selected={date} />

				<label className="base-label">What time is most convenient for you?</label>
				<div className="times">
					<BaseRadio name="time" value="0" onChange={selectTime} text="Any time" checked={!time} />
					<BaseRadio name="time" value="8" onChange={selectTime} text="8:00 to 12:00" checked={time === 8} />
					<BaseRadio name="time" value="12" onChange={selectTime} text="12:00 to 16:00" checked={time === 12} />
					<BaseRadio name="time" value="16" onChange={selectTime} text="16:00 to 22:00" checked={time === 16} />
				</div>
			</div>


			<div className="block budget">
				<h2>Payments</h2>

				<div className="base-label">By default, the budget is fixed: caregivers cannot offer a price. Check the box below to allow caregivers to offer their own rate. Minimum price of work: <b>25$</b></div>

				<div className="flex">
					<div className="title">Budget:</div>

					<div className="price">
						<span>$</span>
						<input type="number" min="25" step="1" value={price} onInput={e => setPrice(e.target.value)} />
						<img src={suggestPrice ? IconUnlock : IconLock} alt="Suggest" className={classNames({ unlock: suggestPrice })} />
					</div>

					<BaseCheckbox
						checked={suggestPrice}
						onValue={setSuggestPrice}
						text="Providers can suggest price"
					/>
				</div>
			</div>

			<div className="error"><b>{error.type}</b>&nbsp;{error.message}</div>
			<button className="submit" onClick={submitJob}>
				{jobId ? "Update Job" : "Post Job"}
			</button>

			<Completed redirect="/jobs/my" show={completed} setShow={setCompleted} />
		</div>
	)
};

export default observer(JobCreate);