import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Categories } from "utils/constants";

import Dropdown from "components/Base/Dropdown";

import IconSearch from "./img/search.svg";
import IconArrow from "./img/arrow-down.png";

const Search = ({ onSearch }) => {
	const [canSearch, setCanSearch] = useState(false);
	const [search, setSearch] = useState({
		query: "",
		category: "",
		city: ""
	});

	const { state } = useLocation();

	useEffect(() => {
		if (state?.search) {
			const query = state.search;
			setSearch(prev => ({ ...prev, query }));
			onSearch({ query });
		}
	}, [state, onSearch]);

	const handleSearchInputs = useCallback((e) => {
		setCanSearch(true);
		setSearch(prev => ({ ...prev, [e.target.name]: e.target.value }));
	}, []);

	const handleSearchCategory = useCallback((name) => {
		setCanSearch(true);
		setSearch(prev => {
			const category = prev.category === name ? "" : name;
			return { ...prev, category };
		});
	}, []);

	const startSearch = useCallback((e) => {
		e.preventDefault();
		onSearch(search);
		setCanSearch(false);
	}, [search, onSearch]);

	return (
		<form className="search" onSubmit={startSearch}>
			<div className="query block">
				<img src={IconSearch} alt="Search" />
				<input type="text" placeholder="Search by keyword" value={search.query} onChange={handleSearchInputs} name="query" />
			</div>
			<div className="category block">
				<Dropdown items={Categories} onChange={handleSearchCategory} withReset>
					{search.category ? (
						<div className="cat">{search.category}</div>
					) : (
						<div className="placeholder">Category</div>
					)}
					<img src={IconArrow} className="arrow" alt="Arrow" />
				</Dropdown>
			</div>
			<div className="city block">
				<input type="text" placeholder="City" value={search.city} onChange={handleSearchInputs} name="city" />
			</div>

			<button className="find block" onClick={startSearch} disabled={!canSearch}>Search</button>
		</form>
	)
};

export default Search;