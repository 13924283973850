import io from "socket.io-client";
import Notifications from "store/notifications";
import Auth from "store/auth";

const host = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_HOST : null;

const socket = io(host, {
	transports: ["websocket"]
});

export const authSocket = () => {
	const token = localStorage.token;

	if (token && !socket.isAuth) {
		socket.emit("auth", token);
		console.log("Socket authorized");

		Notifications.create({
			title: "Websocket",
			content: "Connection successfully established"
			// content: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vitae iste reiciendis quam dolore ullam tempora eum rerum officia fugit nam non, suscipit maxime adipisci deleniti aliquid ipsum nisi. Quos, ipsam.",
			// date: new Date().toISOString()
		});
	}

	socket.isAuth = !!token;
};


socket.on("connect", authSocket);
socket.on("disconnect", () => socket.isAuth = false);
socket.on("authResponse", (auth) => {
	socket.isAuth = auth;
	Auth.socketAuhorize(auth);
});

socket.on("newMessage", ({ message }) => {
	if (!message.isMe) {
		Notifications.create({
			title: "New message",
			content: `You have new message from ${message.from}`,
			date: message.date_create
		});

		Notifications.setUnreadedChats(true);
	}
});

socket.on("unreadedChats", (count) => {
	Notifications.setUnreadedChats(count);
});

export default socket;