import { JobEndTimes } from "./constants";
import moment from "moment";
import Notifications from "store/notifications";

export const getJobTimes = (time) => {
	return time ? `${time}:00 - ${JobEndTimes[time]}:00` : "any time";
};

export const inNodePath = (path, name) => {
	return !!path.find(p => p.classList?.contains(name));
};

export const changePageTitle = (title) => {
	document.title = title ? `Emissary | ${title}` : `Emissary`;
};

export const parseError = (err) => {
	const error = err.response?.data?.error;

	if (error) {
		return {
			type: error.field ?? "Error",
			message: error.message ?? error
		};
	}
	else {
		return {
			type: "Error",
			message: "Unexpected error"
		}
	}
};


export const getAvatar = (avatar) => {
	if (!avatar) return "/img/avatar.svg";

	if (avatar.startsWith("/")) {
		if (process.env.NODE_ENV === "production") {
			return avatar;
		}

		return `http://${process.env.REACT_APP_API_HOST}${avatar}`;
	}

	return avatar;
}


export const errorNotification = (error, parse = false) => {
	if (parse) {
		error = parseError(error);
	}

	Notifications.create({
		type: "error",
		title: error?.type || "Error",
		content: error?.message || error,
	});
};


export const dateFromNow = (date) => {
	const current = moment();
	const creation = moment(date);
	const diff = current.diff(creation, "days");

	if (diff > 0) {
		return creation.format("DD.MM.YYYY")
	} else {
		return creation.startOf("minute").fromNow();
	}
};


if (!("path" in Event.prototype)) {
	Object.defineProperty(Event.prototype, "path", {
		get: function () {
			var path = [];
			var currentElem = this.target;
			while (currentElem) {
				path.push(currentElem);
				currentElem = currentElem.parentElement;
			}
			if (path.indexOf(window) === -1 && path.indexOf(document) === -1)
				path.push(document);
			if (path.indexOf(window) === -1)
				path.push(window);
			return path;
		}
	});
};