import axios from "axios";
import moment from "moment";
import classNames from "classnames";
import { useCallback, useState } from "react";
import { getJobTimes, parseError } from "utils/helpers";

import IconClose from "./img/close.svg";

const OrderOptions = ({ isShow, onHide, onBid, job, isSuggest = false }) => {
	const [price, setPrice] = useState(() => job.price);
	const [message, setMessage] = useState("");
	const [error, setError] = useState({});

	const sendBid = useCallback(() => {
		axios.post(`/jobs/${job._id}/bids`, {
			price: isSuggest ? price : null,
			message
		}).then(({ data }) => {
			onBid(data.bid);
		}).catch(e => {
			setError(parseError(e));
		});
	}, [job, price, message, onBid, isSuggest]);

	return (
		<div className={classNames("overlay", { show: isShow })} onClick={onHide}>
			<div className="order" onClick={e => e.stopPropagation()}>
				<div className="details">
					<h2>
						<span>Order Options</span>
						<img src={IconClose} alt="Close" title="Close" onClick={onHide} />
					</h2>

					<div className="flex row">
						<div className="box half">
							<div className="title">When</div>
							<div className="info">
								{moment(job.date).format("DD MMMM, YYYY")} <br />
								Part of day: {getJobTimes(job.time)}
							</div>
						</div>

						<div className="box half">
							<div className="title">Where</div>
							<div className="info">
								USA, {job.location?.city}, {job.location?.zip}<br />
								{job.location?.street}
							</div>
						</div>
					</div>

					<div className="box price">
						<div className="left">{isSuggest ? "Suggestion Price" : "Fixed Price"}</div>
						<div className="right">
							{isSuggest ? (
								<>
									$<input type="number" step="1" value={price} disabled={!isSuggest} onInput={e => setPrice(isSuggest ? e.target.value : price)} />
								</>
							) : (
								<>${job.price}</>
							)}
						</div>
					</div>

					<div className="box">
						<h3>Your message</h3>
						<p>You can add a small message to your bid to make it clear that you want to work for this job</p>
						<div className="message">
							<textarea rows="3" placeholder={`Enter your message to ${job.creator.name}...`} onInput={e => setMessage(e.target.value)} maxLength="512"></textarea>
							<div className="chars">{message.length}/512</div>
						</div>
					</div>
				</div>

				<div className="bottom">
					<div className="error"><b>{error.type}</b>{error.message}</div>
					<button className="send" onClick={sendBid}>Send bid</button>
				</div>
			</div>
		</div>
	)
};

export default OrderOptions;