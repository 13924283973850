import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { getAvatar } from "utils/helpers";

import BaseTabs from "components/Base/BaseTabs";
import TabGeneral from "./TabGeneral";
import TabProvider from "./TabProvider";
import TabPassword from "./TabPassword";
// import TabPayments from "./TabPayments";

const Tabs = {
	provider: ["General Information", "Provider profile", /*"Earnings history",*/ "Change password"],
	customer: ["General Information", /*"Payment history",*/ "Change password"]
};


const ProfileEdit = () => {
	const [tab, setTab] = useState(null);
	const [currentTabs, setCurrentTabs] = useState([]);

	const store = useStore();
	const { isProvider, isCustomer, type, id, name, surname, avatar } = store.auth.user;

	useEffect(() => {
		if (type) {
			const tabs = Tabs[type];
			setCurrentTabs(tabs);
			setTab(tabs[0]);
		}
	}, [type]);

	return (
		<div className="page profile-edit">
			<div className="profile-user">
				<div className="avatar">
					<img src={getAvatar(avatar)} alt="User" />
				</div>

				<div className="info">
					<div className="name">{name} {surname}</div>
					<div className="type">{type}</div>
				</div>

				<Link className="button" to={`/${type}s/${id}`}>Profile preview</Link>
			</div>

			<BaseTabs tabs={currentTabs} onTab={setTab} current={tab}>
				{isProvider && (
					<>
						{tab === Tabs.provider[0] && <TabGeneral />}
						{tab === Tabs.provider[1] && <TabProvider />}
						{/* {tab === Tabs.provider[2] && <TabPayments />} */}
						{tab === Tabs.provider[2] && <TabPassword />}
					</>
				)}

				{isCustomer && (
					<>
						{tab === Tabs.customer[0] && <TabGeneral />}
						{/* {tab === Tabs.customer[1] && <TabPayments />} */}
						{tab === Tabs.customer[1] && <TabPassword />}
					</>
				)}
			</BaseTabs>
		</div>
	)
};

export default observer(ProfileEdit);